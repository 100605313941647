import React, { Component } from 'react'
import { Collapse, Layout } from 'antd';

const { Panel } = Collapse;
const { Content } = Layout;

export default class FAQfood extends Component {
    render() {
        return (
            <div className="body">
                <div className="section-banner section-blue ">
                            {/* <img style={{width:100}} src={logo} alt=""/> */}
                            <h2 style={{color:'white', margin:'1%',fontWeight:'bold', textAlign:'center'}}> Frequenly Asked Quetion</h2>
                        
                
                <Content
                    className="site-layout-background"
                    style={{
                        paddingTop: 14,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                <Collapse 
                    accordion 
                    expandIconPosition={'right'}
                    >
                    <Panel header="Bagaimana saya mengakses riwayat transaksi atau pendapatan?" key="1">
                    <p>Transaksi terakhir akan selalu ditunjukkan pada layar utama. Jika anda ingin melihat riwayat transaksi atau pendapatan pada tanggal tertentu, silahkan mengikuti langkah-langkah berikut:</p>
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>Masuk ke aplikasi Anterin Bisnis.</li>
                        <li>Lalu klik ikon ‘Profil’ pada halaman aplikasi Anterin Bisnis.</li>
                        <li>Kemudian klik ‘Riwayat Transaksi atau Pendapatan’, kemudian akan muncul saldo pendapatan harian, mingguan, dan bulanan dari restoran anda.</li>
                    </ol>
                    </Panel>
                    <Panel header="Bagaimana cara mengiklankan restoran saya di aplikasi Anterin?" key="2">
                    <p>Apabila ingin mengiklankan restoran anda di Aplikasi Anterin, maka anda perlu membeli paket iklan pada Anterin Bisnis. Untuk membeli paket iklan silahkan mengikuti langkah-langkah berikut:</p>                    
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>Pastikan anda sudah masuk ke aplikasi Anterin Bisnis.</li>
                        <li>Pilih ikon <strong>’Iklan’</strong> pada halaman Anterin Bisnis.</li>
                        <li>Klik ikon <strong>‘Tambahkan Iklan’</strong> pada halaman Anterin Bisnis.</li>
                        <li>Anda bisa memilih kategori iklan sesuai dengan kebutuhan Merchant</li>
                        <li>Setelah itu klik kategori yang ingin dibeli.</li>
                    </ol>
                    <p>Jika anda mengalami masalah ketika ingin mendaftarkan iklan produk di aplikasi Anterin, silahkan menghubungi Anita dengan cara mengklik <strong>‘Anterin Care’</strong>di Profil aplikasi Anterin Bisnis, kemudian klik icon <strong>‘Anita’</strong></p>
                    </Panel>
                    <Panel header="Bagaimana saya bisa merubah informasi rekening bank saya?" key="3">
                    <p>Untuk &nbsp;merubah informasi rekening bank, anda bisa mengirim permintaan perubahan data merchant ke pihak kami melalui email mitra@anterin.id. Apabila anda masih mengalami kesulitan dalam mengirimkan permintaan perubahan data melalui email, silahkan menghubungi Anita dengan cara mengklik <strong>‘Anterin Care’ </strong>di Profil aplikasi Anterin Bisnis, kemudian klik icon <strong>‘Anita’</strong></p>
                    </Panel>
                    <Panel header="Bagaimana caranya mengubah kata sandi akun Anterin Bisnis?" key="4">
                    <p>Apabila anda ingin merubah kata sandi akun Anterin Bisnis, silahkan mengikuti langkah-langkah berikut:</p>
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>Pastikan anda sudah memasuki home Anterin Bisnis.</li>
                        <li>Klik ikon <strong>‘Profil’</strong> pada halaman home Anterin Bisnis.</li>
                        <li>Klik ikon <strong>‘Keamanan’</strong> pada home menu Anterin Bisnis.</li>
                        <li>Lalu anda akan diarahkan untuk merubah kata sandi yang lama menjadi kata sandi baru. Setelah semua data lengkap langkah seharusnya Anda hanya klik ikon <strong>‘simpan’</strong> kemudian secara otomatis akan menyimpan kata sandi baru yang telah dibuat.</li>
                    </ol>

                    <p>Jika anda mengalami masalah pergantian kata sandi silahkan menghubungi Anita dengan cara mengklik <strong>‘Anterin Care’&nbsp;</strong>di Profil aplikasi Anterin Bisnis, kemudian klik icon <strong>‘Anita’</strong></p>
                    </Panel>
                    <Panel header="Bagaimana cara menambahkan menu baru di Anterin Bisnis?" key="5">
                    <p>Jika anda ingin mengedit daftar menu yang sudah terdaftar, anda hanya perlu mengikuti langkah –langkah berikut:</p>
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>Pastikan anda sudah masuk ke Aplikasi Anterin Bisnis</li>
                        <li>Anda diarahkan untuk mengklik ikon ‘Resto’</li>
                        <li>Kemudian klik ikon ‘+’, silahkan melengkapi data menu yang ingin ditambahkan.</li>
                        <li>Setelah semua data lengkap kemudian klik ikon ‘simpan’ kemudian secara otomatis akan menyimpan daftar menu baru yang telah dibuat.&nbsp;</li>
                    </ol>
                    </Panel>
                    <Panel header="Bagaimana cara mengedit menu di Anterin Bisnis?" key="6">
                    <p>Transaksi terakhir akan selalu ditunjukkan pada layar utama. Jika anda ingin melihat riwayat transaksi atau pendapatan pada tanggal tertentu, silahkan mengikuti langkah-langkah berikut:</p>
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>Pastikan anda sudah masuk ke Aplikasi Anterin Bisnis</li>
                        <li>Silahkan mengklik ikon <strong>‘Resto’&nbsp;</strong></li>
                        <li>Kemudian klik ikon <strong>‘Pensil’&nbsp;</strong>setelah itu ubah keterangan menu sesuai dengan keinginan anda.</li>
                        <li>Setelah semua data lengkap, klik ikon <strong>‘simpan’</strong> kemudian data menu yang telah diedit secara otomatis akan tersimpan pada daftar menu.</li>
                    </ol>
                    </Panel>
                    <Panel header="Bagaimana cara menambahkan merchant atau cabang restoran?" key="7">
                    <p>Apabila Anda  ingin menambahkan merchant baru di Anterin Bisnis, Anda tidak perlu khawatir. Silahkan mengikuti langkah-langkah berikut:</p>
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>Pastikan anda sudah masuk ke Aplikasi Anterin Bisnis</li>
                        <li>Pilih ikon <strong>‘Profil</strong></li>
                        <li>Kemudian klik ikon <strong>‘Tambah Merchant’</strong></li>
                        <li>Isi data selengkap-lengkapnya pastikan GPS terhubung, masukkan alamat cabang Merchant dengan benar.</li>
                        <li>Lalu, klik ikon <strong>‘Submit’</strong> data otomatis akan di verifikasi oleh pihak kami</li>
                    </ol>
                    <p>Jika anda mengalami masalah penambahan merchant atau cabang restorant silahkan menghubungi Anita dengan cara mengklik <strong>‘Anterin Care’</strong>di Profil aplikasi Anterin Bisnis, kemudian klik ikon <strong>‘Anita.</strong></p>
                    </Panel>
                    <Panel header="Kapan saldo AnterinPay saya bisa dikirim ke rekening Bank saya?" key="8">
                    <p>Saldo AnterinPay akan masuk ke rekening anda selama kurang lebih H+1. Jika saldo AnterinPay anda dikirim ke Bank bank anda selama waktu yang ditentukan, kami mohon maaf atas kejadian tersebut. Silahkan menghubungi Anita dengan cara mengklik <strong>‘Anterin Care’</strong>di Profil aplikasi Anterin Bisnis, kemudian klik ikon <strong>‘Anita’</strong></p>
                    </Panel>
                </Collapse>
                </Content>
                </div>
            </div>
        )
    }
}