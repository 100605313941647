import React, { Component } from "react";
import "./App.css";
import { Route, BrowserRouter,Switch } from "react-router-dom";
import TermAndCondition from "./Views/TermsAndCondition";
import FAQ from "./Views/FAQ";
import Program from "./Views/Program";
import PerjanjianAnterinMakan from "./Views/PerjanjianAnterinMakan";
import FAQfood from "./Views/FAQfood";
import AnterinKurir from "./Views/AnterinKurir";

class App extends Component {
  render(){
    return(
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/promo" component={Program}/>
            <Route exact path="/terms" component={TermAndCondition}/>
            <Route exact path="/faq" component={FAQ}/>
            <Route exact path="/perjanjian-anterin-makan" component={PerjanjianAnterinMakan}/>
            <Route exact path="/faq-anterin-makan" component={FAQfood}/>
            <Route exact path="/anterin-kurir" component={AnterinKurir}/>
          </Switch>
        </BrowserRouter>
      </div>
    )
  }
  
}
export default App;
