import React, { Component } from 'react'
import { Collapse } from 'antd';

const { Panel } = Collapse;

export default class PerjanjianAnterinMakan extends Component {

    render(){
        return (
            <div style={{padding:10, backgroundColor:"#eefafc"}}>
                <h3 style={{textAlign:'center'}}>Surat Perjanjian Kerjasama Anterin-Makan</h3>
                <br/>
                <p>Perjanjian kerja sama merupakan satu bagian yang melekat dan tidak dapat
                terpisahkan dari Ketentuan Mitra Anterin. Judul dan penempatan hanyalah sebagai
                referensi untuk memudahkan dan tidak untuk ditafsirkan sebagai materi untuk
                melakukan interpretasi terhadap perjanjian kerja sama ini secara keseluruhan.</p>
                <p>Setiap perjanjian kerja sama yang diatur dan ditetapkan mengatur dan mengikat
                mitra dan pihak Anterin, termasuk namun tidak terbatas pada direksi, dewan
                komisaris, karyawan dan/atau pihak lain yang ditunjuk dan diberikan kewenangan
                dan/atau ditugaskan secara sah oleh Anterin dalam mengakses dan menggunakan
                layanan yang disediakan Anterin.</p>
                <p>Mitra menyatakan telah membaca, memahami, dan menyetujui seluruh isi dan
                terikat dalam perjanjian kerja sama ini, termasuk juga setiap dokumen-dokumen
                yang ada di dalamnya dan/atau terkait padanya.</p>
                <p>Karena itu, Anterin sangat menyarankan mitra untuk membaca secara seksama dan
                hati-hati perjanjian kerja sama ini sebagai referensi pada saat ini atau masa
                mendatang (bilamana terjadi perubahan-perubahan yang dilakukan oleh Anterin).</p>
                <p>Mitra restoran dapat menghubungi Anterin untuk menyampaikan pertanyaan
                mengenai perjanjian kerja sama ini di kontak Anterin yang diberikan di bagian akhir
                perjanjian kerja sama ini.</p>
                <p>Apabila mitra merasa keberatan dan/atau tidak setuju atas salah satu, sebagian,
                atau seluruh isi syarat dan ketentuan dan perjanjian maka berhentilah menggunakan
                dan/atau mengakses layanan anterin makan</p>

                <Collapse accordion 
                    bordered={false}
                    style={{backgroundColor:"#eefafc"}}>
                    <Panel header="DEFINISI" key="1">
                    <ol style={{ paddingLeft: 20 }}>
                        <li><strong>PT Anterin Digital Nusantara</strong> adalah sebuah perseroan terbatas yang didirikan dan beroperasi secara sah berdasarkan hukum Negara Republik Indonesia dan berdomisili di DKI Jakarta, Indonesia yang bergerak di bidang aplikasi perangkat seluler dengan merek dagang ‘Anterin’.</li>
                        <li><strong>Aplikasi Anterin</strong> adalah aplikasi piranti lunak milik PT Anterin Digital Nusantara dengan merek dagang “Anterin” yang merupakan suatu sarana elektronik yang mempertemukan pengguna Aplikasi dengan barang dan jasa yang ditawarkan oleh penyedia jasa.</li>
                        <li><strong>Afiliasi</strong> adalah (i) setiap perusahaan atau badan lainnya yang memiliki kendali atas ANTERIN, (ii) setiap perusahaan atau badan lainnya yang mana ANTERIN memiliki kendali atas perusahaan atau badan lainnya tersebut atau (iii) setiap perusahaan atau badan lainnya yang berada di bawah Kendali yang sama dengan ANTERIN.&nbsp;</li>
                        <li><strong>Formulir </strong>adalah Formulir Pendaftaran Merchant Anterin-Pay yang ditandatangani oleh Merchant untuk mengaktivasi fitur Merchant Wallet yang terdapat di dalam Anterin-Makan.</li>
                        <li><strong>Anterin-Makan</strong> adalah suatu fitur dalam Aplikasi Anterin yang menyediakan layanan jasa pemesanan dan pengantaran makanan dan/atau minuman dari Restoran Merchant kepada Pelanggan.</li>
                        <li><strong>Anterin-Pay</strong> adalah layanan uang elektronik yang diselenggarakan oleh ANTERIN bagi pengguna yang telah mendaftar dan tercatat sebagai pengguna layanan Anterin.</li>
                        <li><strong>Anterin-Bisnis </strong>adalah suatu aplikasi piranti lunak (dan situs portal) milik ANTERIN yang merupakan suatu sarana elektronik untuk membantu Merchant mengelola kegiatan Restorannya yang terdaftar di dalam Anterin-Makan, termasuk namun tidak terbatas pada pengelolaan Informasi Pesanan dan Dompet Merchant.&nbsp;</li>
                        <li><strong>Hak Kekayaan Intelektual</strong> adalah: a. hak paten, merek dagang, hak cipta (termasuk hak dalam perangkat lunak), nama dagang, nama domain internet, topografi, hak desain, hak moral, hakhak dalam data basis, rahasia dagang dan informasi rahasia lainnya, ilmu pengetahuan (know-how) dan hak-hak kekayaan intelektual lainnya, baik terdaftar maupun tidak terdaftar, dan termasuk sedang dalam aplikasi untuk pendaftaran, dan seluruh hak atau bentuk perlindungan yang memiliki efek yang serupa dimanapun di dunia ini; b. hak berdasarkan lisensi, persetujuan, perintah, peraturan perundangundangan atau berdasarkan apapun sehubungan dengan poin (a) di atas; c. hak yang memiliki dampak atau asal yang sama atau serupa dengan poin (a) dan (b) yang saat ini atau di kemudian hari mungkin timbul; dan d. hak untuk menuntut pelanggaran yang sudah ada dari hak-hak yang disebutkan di atas. &nbsp;</li>
                        <li><strong>Hari Kerja</strong> adalah setiap Senin sampai dengan Jumat pada kalender masehi, kecuali hari libur resmi yang sudah ditetapkan oleh Pemerintah Negara Republik Indonesia.</li>
                        <li><strong>Informasi Pesanan</strong> adalah detil informasi terkait dengan pesanan Produk yang dipesan oleh Pelanggan, termasuk namun tidak terbatas pada nomor pesanan, jenis dan jumlah Produk yang dipesan Pelanggan, alamat tujuan pengantaran pesanan, nomor telepon, dan Kode Referral Warga Anterin yang melaksanakan pengambilan dan pengantaran pesanan.&nbsp;</li>
                        <li><strong>Kontrak Elektronik</strong> adalah perjanjian para pihak yang dibuat melalui sistem elektronik sebagaimana diatur dalam Undang-Undang No. 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik dan Peraturan Pemerintah No. 82 Tahun 2012 tentang Penyelenggaraan Sistem dan Transaksi Elektronik.&nbsp;</li>
                        <li><strong>Layanan</strong> adalah jasa layanan pemesanan dan pengantaran makanan dan minuman melalui Anterin-Makan yang dapat diakses melalui Aplikasi Anterin dan untuk proses pengelolaan pemesanannya menggunakan Anterin-Merchant.</li>
                        <li><strong>Merchant</strong> adalah pemilik dan pengelola Restoran yang terdaftar didalam Anterin-Makan dan terdaftar untuk memiliki akses terhadap Anterin-Bisnis.</li>
                        <li><strong>Dompet Merchant</strong> adalah salah satu fitur yang terdapat di dalam Anterin-Bisnis dimana seluruh pembayaran Transaksi akan tercatat di dalamnya dan Merchant dapat melakukan Penerimaan Dana (Settlement) atas saldo yang dimilikinya.</li>
                        <li><strong>Warga Anterin</strong> adalah pihak yang melaksanakan pengantaran Produk (Driver Anterin) yang sebelumnya telah dipesan Pelanggan melalui fitur Anterin-Makan di dalam Aplikasi Anterin.</li>
                        <li><strong>Pelanggan </strong>adalah pengguna terdaftar yang menggunakan Aplikasi Anterin.&nbsp;</li>
                        <li>Produk adalah makanan dan/atau minuman Restoran yang dijual oleh Merchant dan dibeli oleh Pelanggan melalui fitur Anterin-Makan di dalam Aplikasi Anterin.</li>
                        <li><strong>Restoran</strong> adalah rumah makan yang dimiliki dan dikelola oleh Merchant.&nbsp;</li>
                        <li><strong>Settlement</strong> adalah proses pengkreditan dana hasil pembayaran Transaksi yang diproses melalui Anterin-Bisnis ke Rekening Merchant berdasarkan perintah dari Merchant yang dilakukan secara terjadwal.&nbsp;</li>
                        <li><strong>Prosedur Operasi Standar (SOP)</strong> adalah prosedur operasi standar penggunaan Layanan yang dapat diakses di (Web Anterin.id) sebagaimana dapat diubah atau ditambahkan dari waktu ke waktu melalui pemberitahuan kepada Merchant.&nbsp;</li>
                        <li><strong>Transaksi</strong> adalah kegiatan pemesanan dan pembayaran Produk yang dipesan melalui Anterin-Makan dan/atau kegiatan pembelian dan pembayaran Produk dari Merchant yang dilakukan oleh Pelanggan yang diproses melalui AnterinMakan.</li>
                    </ol>
                    </Panel>
                    <Panel header="RUANG LINGKUP" key="2">
                    <ol style={{ paddingLeft: 20 }}>
                        <li>Untuk setiap pemesanan dan pengantaran Produk melalui melalui fitur AnterinMakan dalam Aplikasi Anterin, Para Pihak sepakat bahwa Merchant akan
                        membayar biaya jasa kepada ANTERIN sebesar 10% ( sepuluh persen) dari
                        jumlah harga makanan dan/atau minuman yang dipesan oleh Pelanggan
                        (selanjutnya disebut “Biaya Jasa”). Penghitungan Biaya Jasa akan dihitung
                        berdasarkan Transaksi yang tercatat pada sistem Anterin-Bisnis.</li>
                        <li><strong>Biaya Jasa</strong> pada ayat (1) di atas akan dihitung dari jumlah harga makanan
                        dan/atau minuman yang dipesan oleh Pelanggan tidak termasuk <strong>Pajak
                        Restoran (PB1)</strong> dan Potongan <strong>Biaya layanan</strong> , jika ada (selanjutnya disebut
                        “<strong>Penjualan Bersih</strong>”). Biaya Layanan sudah termasuk Pajak Pertambahan Nilai
                        (PPN) sesuai dengan peraturan perpajakan yang berlaku. Apabila Merchant
                        menerapkan Pajak Restoran (PB1) dan Biaya Layanan, Merchant wajib
                        menginformasikan terlebih dahulu kepada ANTERIN besaran persentase yang
                        diterapkan.</li>
                        <li>Biaya Jasa akan didapatkan oleh ANTERIN dengan melakukan pemotongan
                        dari Dompet Merchant atas setiap pembayaran Transaksi yang diterima oleh
                        Merchant dan ANTERIN dapat menunjuk Afiliasinya untuk melakukan
                        pemotongan dari Dompet Merchant.</li>
                        <li>Pemotongan Biaya Jasa akan dilakukan dari harga Penjualan Bersih Produk.</li>
                        <li>Untuk setiap pemesanan dan pengantaran Produk melalui melalui fitur AnterinMakan dalam Aplikasi Anterin, Para Pihak sepakat bahwa Merchant akan
                        mendapat cashback sebesar 0,75% ( Nol Koma Tujuh Puluh Lima Persen) dari
                        jumlah harga makanan dan/atau minuman yang dipesan oleh Pelanggan. Cash
                        back yang diterima merchant akan dihitung berdasarkan Transaksi yang tercatat
                        pada sistem Anterin-Bisnis.</li>
                    </ol>
                    </Panel>
                    <Panel header="PERSYARATAN PENGGUNA LAYANAN OLEH MERCHANT" key="3">
                    <ol style={{ paddingLeft: 20 }}>
                        <li>Layanan hanya dapat diberikan kepada Merchant yang telah menandatangani
                        Perjanjian dan Formulir.</li>
                        <li>Sebagai bagian dari proses pendaftaran sebagai Merchant, Merchant setuju
                        untuk memenuhi persyaratan administrasi yang diperlukan untuk menggunakan
                        Layanan sebagaimana disebutkan dalam Formulir.</li>
                        <li>ANTERIN dan Afiliasinya memiliki diskresi untuk tidak melanjutkan proses
                        pendaftaran Merchant atau memberhentikan Layanan kepada Merchant karena
                        alasan-alasan tertentu, termasuk tidak terbatas pada:
                            <ol style={{listStyleType: "lower-alpha",paddingLeft:20}}>
                            <li> Merchant pernah atau sedang terlibat dalam tindakan kriminal atau tindakan
                            melanggar norma hukum, sosial, agama dan moral;</li>
                            <li>Merchant pernah atau sedang terlibat dalam kelompok atau organisasi
                            terlarang; atau</li>
                            <li>Merchant pernah atau sedang masuk ke dalam daftar hitam ANTERIN dan
                            Afiliasinya, Bank Indonesia dan Asosiasi Kartu Kredit Indonesia (AKKI).</li>
                            </ol>
                        </li>
                        <li>Merchant wajib menyampaikan pemberitahuan tertulis kepada ANTERIN apabila
                        Merchant mengubah jenis usahanya, serta dalam hal terjadi perubahan dalam
                        susunan kepemilikan, Direksi atau penanggung jawab Merchant dan rekening
                        Merchant yang terdaftar, sekurang-kurangnya 14 (empat belas) Hari Kerja
                        sebelum perubahan tersebut dilakukan.</li>
                    </ol>
                    </Panel>
                    <Panel header="HAK DAN KEWAJIBAN ANTERIN" key="4">
                    <p>Dalam melakukan kerjasama, ANTERIN memiliki hak dan kewajiban sebagai berikut:</p>
                    <ol style={{listStyleType: "lower-alpha", paddingLeft:20}}>
                        <li>Menampilkan informasi mengenai Restoran, termasuk namun tidak terbatas pada menu, alamat, jam operasional, nomor telepon, yang diberikan oleh Merchant dan dapat diperbaharui dari waktu ke waktu pada fitur AnterinMakan sehingga memudahkan Pelanggan mendapatkan informasi mengenai Restoran secara lengkap dan melakukan pemesanan Produk dari Restoran. &nbsp;</li>
                        <li>Mendapatkan biaya jasa, termasuk namun terbatas, seperti biaya layanan atas pemberian Layanan kepada Merchant.&nbsp;</li>
                        <li>Menambahkan dan/atau mengurangi fitur-fitur yang terdapat didalam AnterinMakan dari waktu ke waktu untuk meningkatkan kualitas pelayanan AnterinMakan.&nbsp;</li>
                        <li>Menggunakan Hak Kekayaan Intelektual milik Merchant yang dapat digunakan Anterin dan/atau Afiliasinya terkait dengan tampilan pada AnterinMakan atau untuk tujuan pemasaran.&nbsp;</li>
                        <li>Mengantarkan Produk dalam kemasan yang disediakan oleh Restoran kepada Pelanggan. Anterin berkewajiban untuk memastikan bahwa Warga Anterin yang bersangkutan akan bertanggung jawab atas semua kewajiban yang mungkin timbul dari kerusakan kemasan makanan dan minuman yang timbul pada saat pengantaran dari Gerai Restoran kepada Pelanggan.</li>
                        <li>Menyediakan Prosedur Operasi Standar (SOP) untuk kebutuhan operasional Layanan yang dapat diubah atau ditambahkan dari waktu ke waktu sebagaimana disampaikan kepada Merchant.&nbsp;</li>
                        <li>Menyediakan layanan Merchant Call Unit (MCU) untuk melayani pertanyaan Merchant terkait penggunaan Anterin-Makan</li>
                        <li>Hak dan kewajiban lain yang dinyatakan di dalam Prosedur Operasi Standar (SOP) yang merupakan satu kesatuan dari Perjanjian ini.</li>
                    </ol>
                    </Panel>
                    <Panel header="HAK DAN KEWAJIBAN MERCHANT" key="5">
                    <p>Dalam melakukan kerjasama, Merchant memiliki hak dan kewajiban sebagai berikut:</p>
                    <ol style={{listStyleType: "lower-alpha", paddingLeft:20}}>
                        <li>Mengisi informasi pada aplikasi merchant mengenai Restoran, termasuk
                        namun tidak terbatas pada menu, alamat, jam operasional, nomor telepon
                        kepada ANTERIN dan dapat diperbaharui dari waktu ke waktu pada fitur
                        Anterin-Makan sehingga memudahkan Pelanggan mendapatkan informasi
                        mengenai Restoran secara lengkap dan melakukan pemesanan Produk dari
                        Restoran melalui ANTERIN. Merchant mempunyai kewajiban untuk
                        memastikan bahwa setiap informasi yang diberikan merupakan informasi
                        yang terkini dan akurat.</li>
                        <li>Memastikan bahwa setiap makanan dan minuman yang dijual oleh Merchant
                        dan dikirimkan melalui jasa yang diberikan ANTERIN adalah makanan dan
                        minuman dengan kualitas dan kebersihan yang tinggi dan dalam keadaan
                        yang layak dikonsumsi oleh Pelanggan. Sehubungan dengan kewajiban ini,
                        Merchant dengan ini menyatakan bahwa ia bertanggung jawab atas semua
                        kewajiban yang mungkin timbul dari atau sehubungan dengan konsumsi
                        makanan dan minuman oleh Pelanggan yang dipesan dari Restoran yang
                        dikelola Merchant.</li>
                        <li>Memastikan bahwa Restoran yang dikelolanya telah siap untuk menjalankan
                        Anterin-Makan.</li>
                        <li>Merchant wajib untuk melaporkan kepada ANTERIN apabila terdapat indikasi
                        penyalahgunaan Anterin-Makan atau transaksi Anterin-Pay.</li>
                        <li>Merchant wajib mematuhi ketentuan mengenai pelaksanaan dan prosedur
                        yang berkaitan dengan layanan Anterin-Makan, baik yang tercantum di dalam
                        Perjanjian ini maupun dokumen-dokumen pendukung lainnya, termasuk
                        namun tidak terbatas pada Prosedur Operasi Standar (SOP) yang dapat
                        disampaikan oleh ANTERIN dari waktu ke waktu.</li>
                        <li>Merchant dilarang mencantumkan besaran Biaya Layanan, yang diatur dan
                        disepakati dalam Perjanjian ini, ke dalam bukti//kwitansi/bon (baik dalam
                        bentuk fisik maupun elektronik) yang dikeluarkan Merchant yang akan
                        diterima oleh Pelanggan sebagai bukti pembelian Produk melalui fitur AnterinMakan dalam aplikasi Anterin.</li>
                        <li>Menerima edukasi terkait penggunaan Anterin-Makan.</li>
                        <li>Dalam hal ANTERIN dan/atau Afiliasinya menyediakan alat dan/atau
                        perangkat kepada Merchant sehubungan dengan Layanan yang diberikan
                        sehubungan dengan Syarat dan Ketentuan ini (“Perangkat”), Merchant
                        bertanggung jawab atas Perangkat dan wajib untuk mengembalikan
                        Perangkat kepada ANTERIN dan/atau Afiliasinya setelah pengakhiran
                        Layanan dalam kondisi yang baik. Dalam hal Perangkat rusak dan/atau
                        hilang, ANTERIN dan/atau Afiliasinya berhak untuk memotong harga
                        Perangkat dari Merchant Wallet.</li>
                        <li>Hak dan kewajiban lain yang dinyatakan di dalam Prosedur Operasi Standar
                        (SOP) yang merupakan satu kesatuan dari Perjanjian ini.</li>
                    </ol>
                    </Panel>
                    <Panel header="MASA BERLAKU" key="6">
                    <ol style={{paddingLeft:20}}>
                        <li>Kerjasama berdasarkan Perjanjian ini dimulai sejak tanggal ditandatanganinya
                        Perjanjian ini dan berlangsung untuk periode 2 (dua) tahun (selanjutnya disebut
                        “Masa Berlaku”).</li>
                        <li>Masa Berlaku akan diperpanjang secara otomatis untuk jangka waktu 1 (satu) tahun dan demikian seterusnya, kecuali jika salah satu Pihak menyampaikan pemberitahuan tertulis untuk tidak memperpanjang Masa Berlaku paling lambat 30 (tiga puluh) hari sebelum habisnya Masa Berlaku.</li>
                    </ol>
                    </Panel>
                    <Panel header="PERPAJAKAN" key="7">
                    <ol style={{paddingLeft:20}}>
                        <li>Kecuali ditentukan lain dalam Perjanjian ini, semua jenis pajak, biaya ataupun
                        pungutan lainnya, baik yang ada saat ini maupun yang ada dikemudian hari, yang
                        wajib dibayarkan ke pemerintah yang berkaitan dengan transaksi berdasarkan
                        Perjanjian ini, wajib ditanggung oleh masing-masing Pihak sesuai ketentuan
                        perundang-undangan yang berlaku.</li>
                        <li>Jika diperlukan, Para Pihak setuju untuk saling memberikan dokumen yang
                        berkaitan dengan pembayaran pajak sehubungan Perjanjian ini.</li>
                    </ol>
                    </Panel>
                    <Panel header="HAK KEKAYAAN INTELEKTUAN" key="8">
                    <ol style={{paddingLeft:20}}>
                        <li>Seluruh Hak Kekayaan Intelektual adalah dan akan tetap menjadi milik eksklusif
                        dari Pihak yang memilikinya pada saat penandatanganan Perjanjian ini.</li>
                        <li>Apabila dalam pelaksanaan Perjanjian ini, Para Pihak mempromosikan hasil
                        pekerjaan berupa barang dan/atau jasa ANTERIN, termasuk slogan, gambar,
                        foto, logo yang merupakan properti ANTERIN, gambar, foto, dan logo tersebut
                        akan tetap menjadi milik ANTERIN. Sebaliknya, apabila dalam pelaksanaan
                        Perjanjian ini, Para Pihak mempromosikan hasil pekerjaan berupa barang
                        dan/atau jasa milik Merchant, termasuk slogan, gambar, foto, logo yang
                        merupakan properti Merchant, gambar, foto, dan logo tersebut akan tetap menjadi
                        milik Merchant.</li>
                        <li>Merchant dengan ini setuju untuk menjamin dan membebaskan ANTERIN dari
                        dan terhadap setiap klaim, biaya, tuntutan, kerugian, pertanggungjawaban,
                        kehilangan, ongkos, dan pertanggungjawaban yang timbul atau diajukan oleh
                        pemegang lisensi atau pemegang hak lainnya atas merek dagang yang
                        digunakan Merchant dan ditampilkan pada Aplikasi Anterin.</li>
                    </ol>
                    </Panel>
                    <Panel header="SETTLEMENT" key="9">
                    <p>Ketentuan mengenai Settlement, laporan dan rekonsiliasi akan diatur lebih lanjut
                    dalam Prosedur Operasi Standar (SOP) sebagaimana disampaikan dan dapat
                    diubah dari waktu ke waktu oleh ANTERIN.</p>
                    </Panel>
                    <Panel header="PROMOSI" key="10">
                    <ol style={{paddingLeft:20}}>
                        <li>Untuk mendukung penyediaan layanan Anterin-Makan, Merchant setuju untuk
                        melakukan pemasaran sebagaimana disepakati dengan ANTERIN.</li>
                        <li>Para Pihak dapat menyepakati promosi atau pemasaran lainnya dimana rincian
                        kegiatannya akan didiskusikan dan disepakati lebih lanjut oleh Para Pihak yang
                        akan dituangkan dalam suatu dokumen terpisah.</li>
                        <li>Setiap materi promosi dan/atau pemasaran yang dibuat dan akan ditampilkan
                        oleh Merchant terkait Anterin-Makan harus mematuhi pedoman pemasaran
                        sebagaimana terdapat di Prosedur Operasi Standar (SOP).</li>
                    </ol>
                    </Panel>
                    <Panel header="PERNYATAAN DAN JAMINAN" key="11">
                    <p>Masing-masing Pihak menyatakan dan menjamin hal-hal sebagai berikut:</p>
                    <ol style={{listStyleType: "lower-alpha", paddingLeft:20}}>
                        <li>(i) jika ia adalah orang perorangan: ia adalah orang perorangan yang berwenang
                        untuk melakukan tindakan hukum berdasarkan peraturan perundang-undangan
                        yang berlaku di Indonesia dan tidak sedang dalam pengampuan; atau (ii) ia
                        adalah badan hukum yang didirikan berdasarkan hukum dan peraturan
                        perundang-undangan yang berlaku di Negara Republik Indonesia;</li>
                        <li>ia memiliki kewenangan yang sah secara hukum untuk menandatangani
                        Perjanjian dan dokumen yang terkait serta melaksanakan kewajibannya
                        berdasarkan Perjanjian ini dan mempunyai segala izin dan persetujuan dalam
                        menjalankan kegiatan usahanya;</li>
                        <li>penandatanganan, pelaksanaan hak dan/atau kewajiban berdasarkan Perjanjian
                        dan Prosedur Operasi Standar (SOP) yang merupakan bagian dari Perjanjian ini
                        tidak melanggar ketentuan anggaran dasar masing-masing Pihak (bagi Pihak
                        yang berbentuk badan hukum), perjanjian apapun di mana masing-masing Pihak
                        terikat menjadi Pihak di dalamnya dan peraturan perundang-undangan yang
                        berlaku;</li>
                        <li>Para Pihak akan menaati hukum dan perundang-undangan yang berlaku,
                        termasuk namun tidak terbatas kepada setiap peraturan perundang-undang anti
                        korupsi yang berlaku di Negara Republik Indonesia;</li>
                        <li>dalam melaksanakan Perjanjian ini, masing-masing Pihak tidak menawarkan,
                        menjanjikan, menyetujui atau mensahkan setiap pembayaran atau pemberian,
                        baik secara langsung maupun tidak langsung, barang atau materi yang
                        mempunyai nilai (termasuk, namun tidak terbatas kepada hadiah, hiburan,
                        makanan, diskon atau kredit pribadi, atau manfaat lainnya yang tidak dibayarkan
                        pada nilai pasar) yang mempunyai tujuan atau efek penyuapan publik atau
                        komersil dan tidak akan mengambil tindakan yang akan membuat Para Pihak
                        melanggar setiap ketentuan dalam peraturan dan hukum anti-penyuapan dan
                        korupsi yang berlaku di Negara Republik Indonesia atau peraturan dan hukum
                        yang melarang setiap tindakan yang melanggar hukum untuk tujuan mendapatkan
                        manfaat komersil bisnis.</li>
                    </ol>
                    </Panel>
                    <Panel header="KEAMANAN DAN PERLINDUNGAN INFORMASI" key="12">
                    <ol style={{paddingLeft:20}}>
                    <li>Keamanan data dan/atau informasi personal dan sensitive Merchant (selanjutnya
                    disebut “Data Merchant”) merupakan hal yang penting bagi ANTERIN. ANTERIN
                    memberlakukan standar keamanan yang wajar untuk melindungi Data Merchant.
                    Meskipun demikian, tidak ada sistem yang tidak dapat ditembus dan hal ini dapat
                    berakibat pada meningkatnya risiko atas Data Merchant. Oleh karenanya, tanpa
                    membatasi Pasal 18 yang berlaku umum, sejauh dimungkinkan berdasarkan
                    hukum dan peraturan perundang-undangan yang berlaku, Merchant setuju untuk
                    melepaskan ANTERIN dari klaim apapun yang timbul sehubungan dengan virus,
                    kerusakan, gangguan, atau bentuk lain dari gangguan sistem, termasuk akses
                    tanpa otorisasi oleh pihak ketiga. Merchant wajib untuk memberitahukan
                    ANTERIN sesegera mungkin jika Merchant mengalami gangguan sistem apapun
                    sebagaimana disebutkan di atas sehingga ANTERIN dapat berusaha
                    memperbaiki gangguan tersebut.</li>
                    <li>Merchant menjamin bahwa Merchant tidak akan melakukan hal-hal sebagai
                    berikut:
                    <ol style={{listStyleType: "lower-alpha", paddingLeft:20}}>
                        <li>Melakukan upaya pemecahan kode (reverse engineering) terhadap sistem
                        Aplikasi ANTERIN, ANTERIN-MAKAN dan/atau Afiliasinya;</li>
                        <li>Melakukan hal-hal yang mengakibatkan kerusakan terhadap sistem
                        Aplikasi ANTERIN, ANTERIN-MAKAN dan/atau Afiliasinya dengan
                        sengaja; atau</li>
                        <li>Melakukan hal-hal yang bertujuan untuk mencuri data Aplikasi ANTERIN,
                        ANTERIN-MAKAN dan/atau Afiliasinya dan/atau Pelanggan.</li>
                        </ol>
                    </li>
                    </ol>
                    </Panel>
                    <Panel header="KERAHASIAAN" key="13">
                    <ol style={{paddingLeft:20}}>
                    <li>Salah satu Pihak dapat memberikan Informasi Rahasia kepada Pihak lainnya
                    dalam Perjanjian ini. Para Pihak sepakat bahwa pemberian, penerimaan dan
                    penggunaan Informasi Rahasia tersebut dilakukan sesuai dengan ketentuan yang
                    diatur dalam Pasal ini.</li>
                    <li>“<strong>Informasi Rahasia</strong>” adalah data dan informasi-informasi yang:
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>diberikan baik oleh ANTERIN kepada Merchant, ataupun oleh Merchant
                        kepada ANTERIN, sehubungan dengan pelaksanaan pemberian layanan
                        dari ANTERIN kepada Merchant ini;</li>
                        <li>merupakan hak milik dari, mengenai atau dibuat oleh salah satu Pihak; dan</li>
                        <li>mengenai salah satu Pihak yang memberikan Pihak tersebut suatu
                        manfaat bisnis atau kesempatan untuk memperoleh manfaat tersebut atau
                        pengungkapan dari hal mana dapat merugikan kepentingan Pihak tersebut.</li>
                    </ol>
                    </li>
                    <li>Seluruh informasi dan data terkait dengan Perjanjian ini yang diterima oleh Para
                    Pihak harus dijaga kerahasiaannya dan Para Pihak sepakat untuk tidak
                    memberitahukan dan/atau memberikan data sebagian ataupun seluruhnya
                    kepada pihak ketiga manapun juga atau menggunakan informasi dan data
                    tersebut untuk kepentingan Para Pihak yang tidak berhubungan dengan
                    pelaksanaan Perjanjian ini, kecuali:
                        <ol style={{listStyleType: "lower-alpha"}}>
                        <li>atas persetujuan tertulis dari Pihak lainnya;</li>
                        <li>informasi yang diungkapkan oleh suatu Pihak kepada pegawainya,
                        banknya, konsultan finansialnya, konsultan hukumnya, atau konsultan
                        lainnya sehubungan dengan Perjanjian ini;</li>
                        <li>data tersebut sudah merupakan informasi milik umum, bukan karena
                        kesalahan Pihak yang menerima informasi;</li>
                        <li>harus diberikan berdasarkan ketentuan hukum yang berlaku;</li>
                        <li>berdasarkan ketetapan pengadilan atau arbitrase; atau</li>
                        <li>diberikan oleh ANTERIN kepada Afiliasinya dan/atau pihak lainnya yang
                        memiliki hubungan kerja sama dengan ANTERIN.</li>
                        </ol>
                    </li>
                    <li>Masing-masing Pihak sepakat bahwa akses terhadap Informasi Rahasia hanya
                    akan diberikan kepada Direktur, karyawan, akuntan dan/atau penasehat yang
                    memerlukan akses terhadap Informasi Rahasia untuk kepentingan pelaksanaan
                    Perjanjian ini dan dengan basis need-to-know. Masing-masing Pihak bertanggung
                    jawab atas penggunaan Informasi Rahasia oleh Direktur, karyawan, akuntan
                    dan/atau penasehatnya tersebut. Para Pihak wajib memastikan bahwa seluruh
                    direktur, komisaris, pegawai, agen, dan konsultannya mematuhi kewajiban
                    kerahasiaan berdasarkan Perjanjian ini.</li>
                    <li>Klausul kerahasiaan akan berlaku dan mengikat Para Pihak sejak berlaku
                    efektifnya Perjanjian ini dan akan tetap bertahan dan berlaku sampai dengan 5
                    (lima) tahun setelah Perjanjian ini berakhir.</li>
                    <li>Dalam hal salah satu Pihak lalai atau melanggar untuk mematuhi kewajibannya
                    untuk menjaga kerahasiaan seperti tersebut diatas, maka Pihak lainnya dapat
                    mengakhiri Perjanjian ini sesuai dengan ketentuan dalam Perjanjian ini,
                    disamping Pihak yang lalai atau bertanggung jawab akan bertanggung jawab atas
                    segala kerugian yang diderita oleh Pihak pemilik Informasi Rahasia tuntutan dari
                    pihak manapun juga akibat pelanggaran dari ketentuan Kerahasiaan ini.</li>
                    </ol>
                    </Panel>
                    <Panel header="KOREKSI DAN KLAIM" key="14">
                    <ol style={{paddingLeft:20}}>
                    <li>Dalam hal ANTERIN menganggap ada kesalahan dalam Transaksi, ANTERIN
                    dapat sewaktu-waktu melakukan koreksi atas kesalahan Transaksi tersebut,
                    termasuk namun tidak terbatas pada memotong Dana Transaksi. ANTERIN akan
                    memberitahukan Merchant secara tertulis mengenai koreksi tersebut.</li>
                    <li>Ketentuan mengenai koreksi dan klaim akan diatur lebih lanjut dalam Prosedur
                    Operasi Standar (SOP) sebagaimana disampaikan dan dapat diubah dari waktu
                    ke waktu oleh ANTERIN.</li>
                    </ol>
                    </Panel>
                    <Panel header="KUASA PENDEBETAN REKENING MERCHANT" key="15">
                    <ol style={{paddingLeft:20}}>
                    <li>Merchant dengan ini memberi kuasa kepada ANTERIN dan/atau Afiliasinya untuk mendebet atau mengkredit Merchant Wallet sebagaimana dimaksud dalam Perjanjian ini, Formulir dan Prosedur Operasi Standar (SOP) yang berlaku tanpa pemberitahuan terlebih dahulu kepada Merchant dan menggunakan dana hasil pendebetan atau pemotongan tersebut untuk pemenuhan kewajiban Merchant kepada ANTERIN.&nbsp;</li>
                    <li>Kuasa yang diberikan oleh Merchant sebagaimana dimaksud di atas tidak akan berakhir oleh sebab apapun termasuk alasan-alasan sebagaiman dimaksud dalam Pasal 1813, 1814, 1816 Kitab Undang-Undang Hukum Perdata selama Merchant masih memiliki kewajiban terhadap ANTERIN</li>
                    </ol>
                    </Panel>
                    <Panel header="FORCE MAJEURE" key="16">
                    <ol style={{paddingLeft:20}}>
                    <li>Apabila dalam pelaksanaan syarat-syarat dan ketentuan-ketentuan Perjanjian ini
                    menjadi tidak mungkin dilaksanakan oleh salah satu Pihak dan berakibat
                    berakhirnya Perjanjian ini karena keadaaan di luar kuasa pihak tersebut termasuk
                    namun tidak terbatas dalam hal ini kejadian-kejadian yang alamiah, kecelakaan
                    lalu lintas, pemogokan umum, kerusuhan, peledakan bom, bencana alam, perang,
                    kebakaran, kebanjiran, epidemia, karantina, pemberontakan dan kebijaksanaan
                    Pemerintah serta peraturan-peraturan Pemerintah (selanjutnya disebut ”Force
                    Majeure”), maka masing-masing pihak tidak dapat dinyatakan wanprestasi karena
                    kegagalan untuk melaksanakan syarat-syarat dan ketentuan-ketentuan Perjanjian
                    ini.</li>
                    <li>Dalam hal terjadi Force Majeure, maka pihak yang mengalami Force Majeure
                    wajib memberitahukan secara tertulis kepada pihak lainnya mengenai terjadinya
                    Force Majeur tersebut selambat-lambatnya 14 (empat belas) hari kalender
                    terhitung sejak tanggal terjadinya Force Majeure. Segera setelah diterimanya
                    pemberitahuan tertulis tertang adanya Force Majeure tersebut, Para Pihak akan
                    mengadakan musyawarah untuk menentukan akibat dari Force Majeure tersebut
                    serta cara penyelesaiannya.</li>
                    <li>Kelalaian atau keterlambatan dalam memenuhi kewajiban sebagaimana dimaksud
                    pada ayat (2) di atas oleh pihak yang mengalami Force Majeure mengakibatkan
                    tidak diakuinya keadaan yang menimpa pihak tersebut sebagai Force Majeure.</li>
                    <li>Semua kerugian dan biaya yang diderita oleh salah satu Pihak sebagai akibat
                    terjadinya Force Majeure akan menjadi tanggung jawab Pihak yang mengalami
                    Force Majeure tersebut dan tidak akan dalam bentuk apapun menimbulkan
                    kewajiban ataupun beban kepada pihak lainnya.</li>
                    </ol>
                    </Panel>
                    <Panel header="GANTI RUGI DAN BATASAN TANGGUNG JAWAB" key="17">
                    <ol style={{paddingLeft:20}}>
                    <li>Merchant dengan ini setuju untuk mengganti kerugian dan membebaskan
                    ANTERIN dari setiap dan seluruh klaim, kewajiban, atau tuntuan kerugian
                    terhadap, atau diancam untuk dibawa terhadap ANTERIN, oleh pihak manapun,
                    sehubungan dengan: 
                    <ol style={{listStyleType: "lower-alpha"}}>
                    <li>tindakan penipuan, kriminal atau tindakan tidak sah yang
                    dilakukan Merchant atau karyawannya;</li>
                    <li>setiap akses pihak ketiga yang tidak
                    sah atau ilegal terhadap Informasi Rahasia yang diakibatkan oleh kesalahan
                    dan/atau kelalaian Merchant;</li>
                    <li>setiap tindakan kelalaian atau disengaja oleh
                    Merchant atau karyawannya; dan</li>
                    <li>setiap hal sehubungan dengan Produk
                    dan/atau layanan Merchant kepada Pelanggan.</li>
                    </ol>
                   </li>
                    <li>Kewajiban ANTERIN atas setiap klaim yang diajukan sehubungan dengan jasa
                    yang diatur dalam Perjanjian ini tidak akan melebihi jumlah yang setara dengan
                    keseluruhan Biaya Layanan yang dibayarkan oleh Merchant kepada ANTERIN
                    sehubungan dengan pemberian Layanan dalam periode 1 (satu) bulan sebelum
                    terjadinya pelanggaran.</li>
                    <li>ANTERIN tidak akan bertanggung jawab atas segala bentuk kerugian tidak
                    langsung, kerugian insidental, kerugian dalam bentuk hilangnya peluang,
                    pendapatan, keuntungan, gangguan usaha, atau biaya asuransi.</li>
                    </ol>
                    </Panel>
                    <Panel header="PENGAKHIRAN TANGGUNG JAWAB" key="18">
                    <ol style={{paddingLeft:20}}>
                    <li>Perjanjian berakhir jika terdapat salah satu dari kejadian-kejadian sebagai berikut:
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>Berakhirnya Masa Berlaku yang tertuang dalam Perjanjian;</li>
                        <li>Para Pihak sepakat untuk mengakhiri Perjanjian sebelum berakhirnya
                        Masa Berlaku;</li>
                        <li>Pemberitahuan dari ANTERIN kepada Merchant jika Merchant melanggar
                        Perjanjian</li>
                    </ol></li>
                    <li>ANTERIN berhak untuk menghentikan Layanan yang diberikan kepada Merchant
                    dengan menyampaikan pemberitahuan tertulis kepada Merchant selambatlambatnya 30 (tiga puluh) hari sebelum tanggal pengakhiran yang dikehendaki.</li>
                    <li>Apabila pada saat berakhirnya Perjanjian masih terdapat hak dan kewajiban yang
                    belum diselesaikan oleh masing-masing Pihak, maka ketentuan-ketentuan dalam
                    Perjanjian tetap berlaku dan mengikat sampai dengan diselesaikannya hak dan
                    kewajiban tersebut oleh masing-masing Pihak.</li>
                    <li>Para Pihak sepakat untuk mengesampingkan berlakunya ketentuan Pasal 1266
                    Kitab Undang-Undang Hukum Perdata, sehingga pengakhiran yang dimaksud
                    dapat dilakukan tanpa memerlukan putusan pengadilan dan cukup dilakukan
                    dengan pemberitahuan tertulis dari masing-masing Pihak.</li>
                    </ol>
                    </Panel>
                    <Panel header="MANAJEMEN KONTEN" key="19">
                    <ol style={{paddingLeft:20}}>
                        <li>ANTERIN dengan ini memberikan Merchant hak untuk mengunggah dan/atau
                        mengubah data dan/atau informasi mengenai Restoran, termasuk namun tidak
                        terbatas pada menu, alamat, jam operasional, nomor telepon, yang ditampilkan
                        dalam sistem Aplikasi Anterin, Anterin-Makan dan/atau Afiliasinya sesuai dengan
                        pedoman manajemen konten sebagaimana diatur dalam Prosedur Operasi
                        Standar (SOP) yang dapat diperbaharui dari waktu ke waktu oleh ANTERIN.</li>
                        <li>Merchant bertanggung jawab secara penuh atas seluruh konten atau substansi
                        yang diunggahnya dan laporan atau pengaduan mengenai konten yang diunggah
                        oleh Merchant tersebut.</li>
                        <li>Dalam hal Merchant melakukan pelanggaran atas ketentuan manajemen konten
                        sebagaimana diatur pada Pasal ini, maka ANTERIN berhak untuk:
                        <ol style={{listStyleType: "lower-alpha"}}>
                        <li>Melakukan tindakan penghapusan dan/atau pemblokiran terhadap konten
                        yang dilarang;</li>
                        <li>Menghentikan Layanan dengan menutup akses Restoran pada fitur
                        Anterin-Makan dalam Aplikasi Anterin; atau</li>
                        <li>Dengan segera mengakhiri Perjanjian secara sepihak, tanpa
                        pemberitahuan terlebih dahulu kepada Merchant.</li>
                        </ol>
                        </li>
                    </ol>
                    </Panel>
                    <Panel header="HUKUM YANG MENGATUR DAN PENYELESAIAN SENGKETA" key="20">
                    <ol style={{paddingLeft:20}}>
                    <li>Perjanjian ini diatur berdasarkan dan dibuat sesuai dengan hukum dan ketentuan Negara Republik Indonesia dan Para Pihak dengan ini menyatakan tunduk pada peraturan dan hukum yang berlaku.</li>
                    <li>Apabila timbul perselisihan sehubungan dengan penafsiran dan pelaksanaan Perjanjian ini, Para Pihak sepakat untuk menyelesaikan perselisihan yang dimaksud secara musyawarah untuk mufakat antara Para Pihak dalam jangka waktu 30 (tiga puluh) hari sejak salah satu Pihak menyampaikan pemberitahuan mengenai adanya sengketa. Para Pihak akan menuangkan hasil kesepakatan antara Para Pihak sehubungan dengan perselisihan tersebut dalam sebuah berita acara penyelesaian sengketa yang ditandatangani oleh kedua belah Pihak. Ketentuan lebih lanjut sehubungan dengan proses penyelesaian perselisihan antara Para Pihak akan tertuang dalam Prosedur Operasi Standar (SOP).&nbsp;</li>
                    <li>Apabila dalam kurun waktu 30 (tiga puluh) hari sejak penyelesaian secara damai sebagaimana dimaksud pada ayat (2) gagal, maka Para Pihak sepakat untuk menyelesaikan sengketa secara final dan terakhir melalui arbitrase yang diselenggarakan oleh Badan Arbitrase Nasional Indonesia (BANI) sesuai dengan ketentuan-ketentuan Badan Arbitrase Nasional Indonesia (BANI) yang berlaku pada saat itu mengenai prosedur arbitrase. Arbitrase akan dipimpin oleh 1 (satu) orang arbiter yang ditunjuk oleh Ketua Badan Arbitrase Nasional Indonesia (BANI) dan dilaksanakan di DKI Jakarta, Indonesia, menggunakan Bahasa Indonesia. Putusan arbitrase akan dianggap sebagai putusan yang berkekuatan hukum tetap, mengikat dan tidak dapat digugat kembali dan tidak ada satu Pihak pun yang dapat memulai suatu persidangan atau memasukan suatu gugatan di pengadilan manapun sehubungan dengan sengketa yang timbul dari dan sehubungan dengan Perjanjian ini.&nbsp;</li>
                    <li>Dalam hal Para Pihak, sedang dalam proses penyelesaian perselisihan sebagaimana dimaksud pada ayat (2) atau (3) di atas, maka Para Pihak tetap melaksanakan kewajibannnya kecuali disepakati lain oleh Para Pihak.</li>
                    </ol>
                    </Panel>
                    <Panel header="LAIN-LAIN" key="21">
                    <ol style={{paddingLeft:20}}>
                    <li>Merchant dilarang untuk mengalihkan hak dan kewajibannya berdasarkan
                    Perjanjian ini tanpa persetujuan tertulis terlebih dahulu dari ANTERIN. ANTERIN
                    berhak untuk mengalihkan hak dan kewajibannya berdasarkan Perjanjian ini,
                    sebagian dan/atau seluruhnya, kepada Afiliasinya, tanpa persetujuan tertulis
                    terlebih dahulu dari Merchant.</li>
                    <li>Segala hal yang belum diatur dalam Surat Perjanjian ini akan diatur dalam
                    Prosedur Operasi Standar (SOP). Prosedur Operasi Standar (SOP) merupakan
                    bagian yang tidak terpisahkan dari Perjanjian dan dengan mendantangani
                    Perjanjian ini, Merchant setuju untuk tunduk pada Prosedur Operasi Standar
                    (SOP).</li>
                    <li>Segala lampiran yang tercantum pada Perjanjian ini merupakan satu kesatuan
                    yang tidak terpisahkan dari Perjanjian ini.</li>
                    <li>Para Pihak setuju dan sepakat bahwa: (a) Perjanjian ini dibuat dalam bentuk
                    Kontrak Elektronik dan tindakan mengklik persetujuan atau menandatangani
                    secara elektronik merupakan bentuk pernyataan persetujuan atas ketentuan
                    Perjanjian ini sehingga Perjanjian ini sah, mengikat Para Pihak dan dapat
                    diberlakukan; (b) tiada ada Pihak yang akan memulai atau melakukan tuntutan
                    atau keberatan apapun sehubungan dibuatnya maupun keabsahan Perjanjian ini
                    berikut amandemen atau perubahannya dalam bentuk Kontrak Elektronik; dan (c)
                    segala perubahan, amandemen atas Perjanjian ini serta Prosedur Operasi
                    Standar (SOP) yang berlaku, perubahan mana dapat dilakukan oleh ANTERIN
                    atas dasar pertimbangannya sendiri (kecuali pasal mengenai perhitungan Biaya
                    Layanan), dapat dibuat secara elektronik salah satunya dalam bentuk Kontrak
                    Elektronik. Perubahan atas Perjanjian ini dan Prosedur Operasi Standar (SOP)
                    akan berlaku setelah ANTERIN mengumumkan perubahan atas ketentuan
                    Perjanjian dan/atau Prosedur Operasi Standar (SOP) tersebut baik melalui
                    Aplikasi Anterin ataupun melalui media lainnya yang dipilih oleh ANTERIN
                    (termasuk dengan mengirimkan pemberitahuan tertulis secara langsung kepada
                    Merchant). Merchant dapat mengajukan keberatan atas setiap perubahan atau
                    amandemen atas Perjanjian dan/atau Prosedur Operasi Standar (SOP) dengan
                    mengirimkan pemberitahuan tertulis kepada ANTERIN selambat-lambatnya 14
                    (empat belas) hari kalender setelah tanggal pemberitahuan atas perubahan
                    tersebut oleh ANTERIN. Apabila ANTERIN tidak menerima keberatan Merchant
                    dalam waktu yang ditentukan diatas, Merchant akan dianggap telah menyetujui
                    perubahan atau amandemen tersebut dan setuju untuk terikat dengan Perjanjian
                    sebagaimana telah diubah atau ditambahkan. Dalam hal Mechant berkeberatan
                    atas perubahan dan/atau penambahan ketentuan dalam Perjanjian dan/atau
                    Prosedur Operasi Standar (SOP) yang berlaku, ANTERIN berhak untuk
                    menghentikan Layanan kepada Merchant.</li>
                    </ol>
                    </Panel>
                </Collapse>   
                <br/>
                <p><strong>DEMIKIAN SYARAT PERJANJIAN</strong> ini dibuat, Anterin menganjurkan dan medorong
                mitra untuk menjelajahi Anterin untuk mendapatkan informasi lebih lanjut mengenai
                layanan Anterin makan yang disediakan Anterin. Untuk mengajukan pertanyaan,
                laporan, keluhan atau pengaduan kepada Anterin mengenai layanan Anterin makan,
                mitra dapat menghubungi :</p>
                <p>ANITA : (021) 3950 6606 atau 0811-3-222-939 (WA)</p>
                <p>Email : mitra@anterin.id</p>
                <p>Mitra memahami dan menyetujui bahwa Anterin akan mengupayakan penyelesaian
                masalah sebelum disampaikan ke pihak lain. Namun, dalam hal terjadinya kondisi
                tertentu yang menyebabkan tertundanya penyelesaian tersebut sesuai dengan
                hukum dan peraturan perundang – undangan yang berlaku, Anterin akan
                menginformasikan kepada mitra.</p>
           

                
            </div>

        )
    }
}