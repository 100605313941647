import React, { Component } from "react";
import banner from "../Assets/img/banner.jpeg";
import { Layout } from "antd";

const { Content } = Layout;

export default class Program extends Component {
    render(){
        return (
            
            <div>
            
            <div className="body">
                {/* <div  className="container">
                    <img style={{height:50}} src={anterin}/>
                </div> */}
              
                <Content className="content"
                    style={{
                        backgroundColor:'white',
                        // marginTop:60,
                        position:"absolute",
                        height:'100%',
                    }}
                    >

                <div style={{fontSize:13, color:'F8F8F8'}}>Rabu, 12 Desember 2019</div>                       
                    <div style={{fontFamily:'Source Sans Pro', fontWeight:'bold', marginBottom:30, fontSize:30}}>Promo 12.12 Hari Nganterin Nasional </div>        
                <div className="banner">
                    <img style={{width:'100%'}}  src={banner} alt=""></img>
                </div>
                
                <div style={{ marginTop:10}} className="content-text">
                    <ul>    
                        <li style={{marginTop:30}}> Buat Warga Anterin, kamu bisa narik tanpa subscribe</li>

                        <li>Buat Teman Anterin, ada diskon “HANTERNAS” buat kamu!! </li>
                   
                        <li> Buat Distributor, ada bonus 5.000.000 A-Token buat yang Top Up! </li>
                   
                        <li> Yuk, sikat promonya! </li>
                    </ul>

                    <h2 style={{paddingTop:25}}>Syarat dan Ketentuan: </h2>
                    <ol style={{paddingTop:6}}>
                        <li>
                            <p>Promo untuk Warga, Teman dan Distributor hanya berlaku pada 12 Desember 2019 </p>
                        </li>
                       
                        <li>
                           <p> Voucher Teman tidak dapat diuangkan</p>
                        </li>
                     
                        <li>
                           <p> Voucher Teman tidak dapat dipindahtangankan</p>
                        </li>
                        
                        <li>
                            <p>Voucher Teman hanya bisa digunakan 1 kali</p>
                        </li>
                        
                        <li>
                        <p>Anterin berhak membatalkan voucher jika terdeteksi adanya kecurangan atau tidak sesuai syarat dan ketentuan yang berlaku</p>
                        </li>
                    </ol>

                </div>
                
                </Content>
            </div>
        </div>
        )
    }
}