import React, { Component } from 'react'


export default class AnterinKurir extends Component {

    render(){
        return (
            <div className="font" style={{padding:10, backgroundColor:"#fffff"}}>
                <br/>
                <h2 style={{fontFamily:'M PLUS Rounded 1c', fontWeight:'bolder'}}>Syarat dan Ketentuan Pengantaran / Pengiriman Barang :</h2>
                    <ol style={{ paddingLeft: 20, fontSize:18, fontWeight:900 }}>
                        <li>Barang tidak boleh lebih dari 20 kg.</li>
                        <li>Dimensi kiriman tidak boleh lebih dari 70cm(panjang), 50cm(lebar), 50cm(tinggi).</li>
                        <li>Berikut adalah barang yang dilarang untuk dikirim :
                            <ol style={{ listStyleType: "lower-alpha"}}>
                                <li>Barang berbahaya yang atau mudah meledak, menyala, atau terbakar sendiri.</li>
                                <li>Mata uang, emas, permata, perhiasan, atau barang yang bernilai lebih dari Rp10.000.000,-</li>
                                <li>Narkotika dan obat terlarang</li>
                                <li>Senjata api dan amunisinya</li>
                                <li>Barang cetakkan atau benda lain yang mengandung pornografi dan melanggar asusila</li>
                                <li>Hewan hidup dan tanaman yang dilindungi olehundang-undang</li>
                                <li>Barang-barang ilegal atau berbahaya atau barang-barang curian, termasuk namun tidak tebatas pada barang-barang yang mengandung bahan berbahaya dan beracun, obat-obatan atau material terlarang/ilegal.</li>
                            </ol>
                        </li>
                        <li>Dalam hal terdapat pelanggaran atas ketentuan di atas, sepenuhnya menjadi tanggung jawab pengguna dan driver</li>
                    </ol>      
                <br/>
            </div>

        )
    }
}