import React, {Component} from "react"
import { Divider, Collapse } from 'antd';
import CoolTabs from 'react-cool-tabs';

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}


class Content1 extends Component {
    render() {
      return <div className='content' >
        <h2>Umum</h2>
        <Collapse onChange={callback} accordion>
            <Panel header="1. Mengapa harus update ke aplikasi Anterin terbaru?" key="1">
            <p>Aplikasi Anterin yang baru kami rilis sebagai perbaikan dari aplikasi versi sebelumnya sehingga memiliki banyak keunggulan, tampilan yang menarik, berbagai fitur baru yang kami hadirkan seluruhnya tidak hanya untuk memenuhi kebutuhan transportasi pengguna namun juga kebutuhan sehari – hari dengan cara digital.</p>
            </Panel>
            <Panel header="2. Di mana saja aplikasi Anterin terbaru dapat di akses?" key="2">
            <p>Playstore dan iOS (segera hadir)</p>
            </Panel>
            </Collapse>

        <Divider/>
        <h2>Spesifikasi ponsel yang digunakan</h2>
        <Collapse onChange={callback} accordion>
            <Panel header="3. Bagaimana spesifikasi ponsel yang dapat digunakan pada aplikasi Anterin Penumpang terbaru?" key="3">
                <p>Spesifikasi minimal RAM 2GB</p>
            </Panel>
        </Collapse>

        <Divider/>
        <h2>Pendaftaran</h2>
        <Collapse onChange={callback} accordion>
            <Panel header="4. Bagaimana cara unduh dan daftar aplikasi Anterin Teman (Penumpang)?" key="4">
            <ol> 
                <li> <p> Buka Playstore di ponsel Android atau buka Appstore di iPhone. </p> </li>
                <li> <p> Ketik di pencarian Playstore / Appstore dengan kata kunci “Anterin” Tekan unduh dan tunggu hingga proses unduh aplikasi Anterin serta instalasinya selesai. </p></li>
                <li> <p> Setelah proses instalasi selesai, buka aplikasi Anterin.</p></li>
                <p> Di aplikasi, akan muncul menu pembuka Anterin Customer. Silahkan langsung masukkan nomor ponsel Kamu pada kolom nomor ponsel. Secara otomatis, sistem akan langsung menampilkan form registrasi. Jika nomor ponsel yang dimasukan sudah terdaftar, sistem akan menampilkan halaman input OTP. </p>
                <li> <p>Lengkapi data pendaftarannya, meliputi:</p></li>
                <ul>
                    <li>Foto</li>
                        <li>Nama Lengkap</li>
                        <li>Email Aktif</li>
                        <li>Nomor handphone dan </li>
                        <li>Jenis Kelamin </li>
                        <li>silahkan isi data yang benar</li>
                </ul>
                <p> Kode verifikasi akan dikirimkan melalui nomor ponsel yang terdaftar. Jika nomor ponsel yang dimasukkan salah, maka pendaftaran tidak dapat di proses lebih lanjut.</p>
                <p> Mengisi email yang aktif juga penting, karena Kamu  akan mendapat verifikasi email. Dengan mengisi email yang benar, Kamu akan mendapat informasi rincian perjalanan di Anterin. Tidak hanya itu, Kamu juga akan mendapat informasi promo Anterin di email tersebut.</p>
            
                </ol>
            </Panel>
        </Collapse>
        
        <Divider/>
        <h2>Order</h2>
        <Collapse onChange={callback} accordion>
            <Panel header="5. Bagaimana cara melakukan pesanan layanan Motor dan Mobil di aplikasi Anterin terbaru? " key="5">
            <p> Untuk melakukan pesanan layanan Motor dan Mobil dilakukan dengan cara sebagai berikut:</p>
            <ol>
                <li> <p> Masuk ke menu utama aplikasi penumpang (Teman). </p></li>
                <li> <p> Masukkan titik penjemputan dan tujuan. </p></li>
                <li> <p> Tentukan Metode pembayaran ( tunai / AnterinPay ). Jika saldo AnterinPay tidak mencukupi, maka pembayaran akan dialihkan ke tunai.</p> </li>
                <li> <p> Penumpang (Teman) dapat memindahkan layanan Motor ke Mobil atau sebaliknya. </p></li>
                <li> <p> Masukkan catatan jika diperlukan untuk memudahkan pengemudi (Warga) mencari alamat atau yang lainya. </p></li>
                <li> <p> Masukan voucher jika penumpang (Teman) memiliki voucher.</p></li>
                <li> <p> Selanjutnya, tekan tombol "Cari Pengemudi" untuk mencari pengemudi (Warga) di sekitar penumpang (Teman) selama 60 detik. </p></li>
                <li> <p> Pengemudi (Warga) yang berada di sekitar penumpang (Teman) akan muncul dengan variasi harga yang ditawarkan. Penumpang (Teman) dapat memilih lebih dari 1 pengemudi (Warga) yang sesuai kriterianya. Penumpang (Teman) diberi waktu selama 60 detik untuk memilih pengemudi (Warga). Setelah itu, tunggu sampai pengemudi (Warga) memilih Kamu. </p></li>
                <li> <p> Jika sudah dipilih oleh pengemudi (Warga), maka pengemudi (Warga) akan menuju ke lokasi penjemputan. </p></li>
                <li> <p> Tekan "Sudah" jika Kamu sudah bersama pengemudi (Warga). Jika tombol tersebut tidak di tekan, maka pengemudi (Warga) tidak dapat melanjutkan perjalanan untuk pengantaran.</p></li>
                <li> <p> Setelah bersama pengemudi (Warga), selanjutnya menuju ke lokasi tujuan sesuai denagan ada di aplikasi. </p></li>
                <li> <p> Jika sudah sampai tujuan, pengemudi (Warga) akan menyelesaikan order dan penumpang (Teman) wajib memberi penilain untuk pengemudi (Warga) atas layanan yang diberikan selama perjalanan. </p></li>
                
            </ol>
            </Panel>
            <Panel header="6. Bagaimana cara membatalkan pesanan? (Teman) " key="6">
            <p>Terdapat 2 cara bagi penumpang (Teman) Anterin untuk membatalkan pesanannya, yaitu:</p>
            <ol>
                <li> <p> Saat proses pencarian driver (Warga) Anterin dengan waktu 60 detik. Jika waktu 60 detik tersebut sudah habis, maka penumpang (Teman) Anterin diminta untuk memesan ulang. </p></li>
                <li> <p> Ketika proses pemilihan driver (Warga) Anterin dengan waktu 60 detik untuk memilih driver (Warga) Anterin. Jika tidak memilih, maka penumpang (Teman) Anterin diminta untuk memesan ulang.</p></li>
                
            </ol>
            </Panel>
            <Panel header="7. Bagaimana cara membatalkan pesanan?" key="7">
            <p>Terdapat 2 cara bagi penumpang (Teman) Anterin untuk membatalkan pesanannya, yaitu:</p>
            <ol>
                <li> Saat proses pencarian driver (Warga) Anterin dengan waktu 60 detik. Jika waktu 60 detik tersebut sudah habis, maka penumpang (Teman) Anterin diminta untuk memesan ulang.</li>
                <li> Ketika proses pemilihan driver (Warga) Anterin dengan waktu 60 detik untuk memilih driver (Warga) Anterin. Jika tidak memilih, maka penumpang (Teman) Anterin diminta untuk memesan ulang.</li>
            </ol>
            </Panel>
        </Collapse>
        
        <Divider/>
        <h2>Cara Pembayaran</h2>
        <Collapse onChange={callback} accordion>
            <Panel header="8. Bagaimana cara pembayaran di aplikasi Anterin terbaru?" key="8">
            <p>Pada aplikasi Anterin yang baru ada dua metode pembayaran yaitu Anterin Pay dan Tunai.</p>
            </Panel>
        </Collapse>

         <Divider/>
        <h2>AnterinPay</h2>  
        <Collapse onChange={callback} accordion> 
            <Panel header="9. Apa itu AnterinPay?" key="9   ">
            <p>AnterinPay merupakan dompet virtual yang bisa digunakan untuk membayar transaksi-transaksi yang berkaitan dengan layanan di dalam aplikasi Anterin.</p>
            </Panel>
            <Panel header="10. Keuntungan AnterinPay?" key="10">
            <p>Anterin Pay merupakan dompet digital yang bisa digunakan untuk: Membeli masa aktif Membayar perjalanan / trip motor, mobil dan kurir Membeli makanan dan minuman (segera hadir) dan berbagai kegunaan lainnya yang terus kami kembangkan.</p>
            
            <p> Top up (isi saldo) Anterin Pay dapat dilakukan kapan saja dan dimana saja melalui berbagai fasilitas yang disediakan. Bisa menggunakan ATM, Internet Banking, Mobile Banking. </p>
            </Panel>
        </Collapse>

        <Divider/>
      </div>
    }
  }
  class Content2 extends Component {
    render() {
      return <div className="content" >
         <h2>Umum</h2>
        <Collapse onChange={callback} accordion>
            <Panel header="1. Mengapa harus update ke aplikasi Anterin terbaru?" key="1">
                <p>
                Aplikasi Anterin terbaru rutin kami rilis sebagai perbaikan dari aplikasi versi sebelumnya sehingga memiliki banyak keunggulan, tampilan yang menarik, berbagai fitur baru yang kami hadirkan seluruhnya tidak hanya untuk memenuhi kebutuhan transportasi pengguna namun juga kebutuhan sehari – hari dengan cara digital.
                </p>
            </Panel>

            <Panel header="2. Di mana saja aplikasi Anterin terbaru dapat di akses?" key="2">
            <p> Playstore ponsel Android OS 4 sampai dengan OS 9Kapasitas RAM minimal 2GB </p>
            </Panel>
            </Collapse>
            <Divider/>
            <h2>Pendaftaran</h2>
            <Collapse onChange={callback} accordion>
                <Panel header="3. Bagaimana cara unduh dan daftar aplikasi Anterin Driver di ponsel?" key="3">
                <ol>
                    <li> <p> Buka Playstore di ponsel Android </p></li>
                    <li> <p> Ketik di pencarian Playstore dengan kata kunci “Anterin Driver” Unduh dan tunggu hingga proses selesai. </p></li>
                    <li> <p> Setelah proses instalasi selesai, buka aplikasi Anterin Driver. Di aplikasi, akan muncul menu pembuka Anterin Driver. </p></li>
                    <p> Untuk Kamu yang berada di luar wilayah distributor / dealer / agen dan ingin menjadi pengemudi (Warga) Anterin, Kamu bisa langsung daftar online menggunakan nomor ponsel dengan menekan tombol "Daftar Yuk". </p>
                    
                    <p> Untuk Kamu yang berada di wilayah cakupan distributor / dealer / agen dan ingin menjadi pengemudi (Warga) Anterin, Kamu bisa langsung daftar ke distributor / dealer / agen terdekat di kota Kamu. Klik informasinya di tautan berikut: https://m.anterin.id/faq-berlangganan/. </p>
                        <li> <p> Untuk menjadi pengemudi (Warga) Anterin terdapat 5 tahap pengisian data, yaitu: </p></li>
                    <ul>
                        <li>Tahap 1: Isi biodata diri </li>
                            <ul>
                                <li>Nama Lengkap</li>
                                <li>Email Aktif</li>
                                <li>Jenis Kelamin</li>
                                <li>Tanggal Lahir</li>
                                <li>Provinsi</li>
                                <li>Kota, dan</li>
                                <li>Alamat lengkap</li>
                            </ul>
                        <li>Tahap 2: Lengkapi biodata diri </li>
                            <ul>
                                <li>Foto Profile (Harus jelas dan sesuai standar Anterin)</li>
                                <li>Nomor KTP</li>
                                <li>Foto KTP</li>
                                <li>Foto KTP dan Selfie</li>
                            </ul>
                        <li>Tahap 3: Isi data SIM </li>
                            <ul>
                                <li>Nomor SIM</li>
                                <li>Tipe SIM (motor/mobil)</li>
                                <li>Masa berlaku SIM, dan </li>
                                <li>Foto SIM.</li>
                            </ul>
                        <li>Tahap 4: Isi data Kendaraan  </li>
                            <ul>
                                <li>Jenis Kendaraan (motor/mobil)</li>
                                <li>Merek kendaraan</li>
                                <li>Sub merek kendaraan </li>
                                <li>Nomor Polisi kendaraan</li>
                                <li>Nomor STNK dan </li>
                                <li>Foto STNK</li>
                            </ul>
                        <li>Tahap 5: Isi data Kendaraan  </li>
                        <ul>
                            <li>Kesibukan saat ini</li>
                            <li>Daerah/wilayah Beroperasi Anterin</li>
                            <li>Apakah bergabung dengan ojol (Gojek dan Grab)</li>
                            <li>*Tombol "Lanjutkan" akan aktif jika data pada setiap tahapan sudah terisi dan benar. </li>
                        </ul>
                        <li>tahap 6: Konfirmasi data terisi dengan benar dan menyetujui syarat dan ketentuan yang berlaku di PT Anterin Digital Nusanta untuk menjadi mitra driver (Warga) Anterin. </li>  
                    </ul>
                    <p>Jika berhasil melakukan registrasi, Kamu akan mendapatkan feedback dari PT Anterin Digital Nusantara berupa email.</p>
                    <p>Proses approval untuk menjadi mitra driver (Warga) Anterin 2x24 jam dan akan diberitau melalui email yang didaftarkan.</p>
                </ol>
                </Panel>
            </Collapse>

            <Divider/>
        <h2>Order</h2>
        <Collapse onChange={callback} accordion>
            <Panel header="4. Bagaimana cara menerima order yang masuk di aplikasi Anterin terbaru?  " key="4">
            <p>Untuk melakukan pemesanan layanan Motor dan Mobil dilakukan dengan cara sebagai berikut:</p>
            <ol>
                <li> Jika pengemudi (Warga) mendapat order masuk maka akan muncul notifikasi blue screen beserta ringtone sebagai penanda tawaran masuk dari penumpang (Teman) Anterin. </li>
                <li> Tekan tombol "Lihat Tawaran" untuk melihat informasi tawaran. </li>
                <li> Tentukan Metode pembayaran (tunai/AnterinPay). Jika saldo AnterinPay tidak mencukupi, maka pembayaran akan dialihkan ke tunai.Tekan tombol "Ambil" tawaran. Jangan biarkan order masuk diabaikan selama 60 detik.</li>
                <li> Jika Kamu dipilih oleh penumpang (Teman) Anterin, maka sistem akan menampilkan "yeay, anda dipilih oleh penumpang" dan jika Kamu bersedia ambil order tersebut, silahkan tekan tombol "Saya dalam Perjalanan" sebagai informasi ke penumpang (Teman) Anterin bahwa Kamu sedang menuju lokasi penjemputan. Pengemudi (Warga) Anterin tidak dapat melakukan perjalanan jika penumpang (Teman) Anterin belum mengkonfirmasi bahwa penumpang (Teman) Anterin sudah bersama pengemudi (Warga) Anterin dan pengemudi (Warga) Anterin tidak bisa membatalkan order. </li>
                <li> Masukkan catatan jika diperlukan untuk memudahkan pengemudi (Warga) mencari alamat atau yang lainya.</li>
                <li> Jika sudah sampai di lokasi tujuan, tekan tombol "Perjanan Selesai" untuk mengakhiri perjalanan.  </li>
                <li> Setelah selesai pengantaran penumpang (Teman) Anterin, pengemudi (Warga) Anterin diminta untuk memberi penilaian berupa bintang untuk penumpang (Teman) Anterin dan dapat memberi alasan mengapa pengemudi (Warga) memberi bintang dengan jumlah tersebut. </li>
                    <ul>
                        <li>Terdapat fitur chat & call pada layar map sebagai sarana komunikasi dengan penumpang (Teman) Anterin selama proses penjemputan sampai dengan pengantaran ke lokasi tujuan. </li>
                        <li>Informasi yang pengemudi (Warga) dapatkan selama proses penjemputan dan pengantaran penumpang yaitu:</li>
                        <ol>
                            <li> Status aktivitas selama proses penjemputan dan pengantaran penumpang (Teman) Anterin yang terdapat pada header bar informasi.</li>
                            <li> Nama dan foto penumpang (Teman) Anterin</li>
                            <li> Metode pembayaran (Tunai/AnterinPay) </li>
                            <li> Harga total yang harus dibayar oleh penumpang (Teman) Anterin</li>
                            <li> Informasi voucher yang digunakan oleh penumpang (Teman) Anterin (jika menggunakan voucher dan harga yang terpotong voucher)</li>
                            <li> Jarak penjemputan dan lokasi penjemputan</li>
                            <li> Jarak pengantaran dan lokasi pengantaran</li>
                            <li> Catatan / note dari penumpang (Teman) Anterin</li>
                        </ol>
                        <li>Terdapat tombol navigasi pada layar map untuk memudahkan driver (Warga) Anterin selama perjalanan.</li>
                    </ul>
            </ol>
            </Panel>
            <Panel header="5. Bagaimana cara membatalkan pesanan?" key="5">
            <p>Terdapat 2 cara bagi driver (Warga) Anterin untuk membatalkan pesanannya, yaitu:</p>
            <ol>
                <li> Ketika ada tawaran masuk dan sudah memilih order, pada saat itu driver (Warga) Anterin dapat membatalkan tawaran masuk. Tawaran yang dibatalkan akan hilang.</li>
                <li> Pada saat driver (Warga) Anterin telah dipilih oleh penumpang (Teman) Anterin namun order tersebut dibiarkan oleh driver driver (Warga) Anterin selama 15 detik, maka order tersebut dibatalkan otomatis oleh sistem. </li>
            </ol>
            </Panel>
        </Collapse>

        <Divider/>
        <h2>Pembayaran</h2>
        <Collapse onChange={callback} accordion>
            <Panel header="6. Bagaimana cara pembayaran di aplikasi Anterin terbaru?" key="6">
            <p>Pada aplikasi Anterin yang baru ada dua metode pembayaran yaitu AnterinPay dan Tunai.</p>
            </Panel>
        </Collapse>

         <Divider/>
        <h2>AnterinPay</h2>  
        <Collapse onChange={callback} accordion> 
            <Panel header="7. Apa itu AnterinPay?" key="7">
            <p>AnterinPay atau A-Pay merupakan dompet digital Anterin yang bisa digunakan untuk membayar transaksi - transaksi yang dilakukan di dalam aplikasi Anterin.</p>
            </Panel>
        </Collapse>
            
        <Divider/>
        <h2>Keuntungan A-Pay?</h2>  
        <Collapse onChange={callback} accordion> 
            <Panel header="8.Anterin Pay merupakan dompet digital " key="8">
            <p>Anterin Pay merupakan dompet digital yang bisa digunakan untuk: </p>
                <ol>
                    <li> Membeli masa aktif</li>
                    <li> Membayar perjalanan / trip motor, mobil dan kurir</li> 
                    <li> Membeli makanan dan minuman (segera hadir)</li>
                    <li> dan berbagai kegunaan lainnya yang terus kami kembangkan.  </li>
                    <li> Top up (isi saldo) Anterin Pay dapat dilakukan kapan saja dan dimana saja melalui berbagai fasilitas yang disediakan. Bisa menggunakan ATM, Internet Banking, Mobile Banking. </li>
                </ol>
                </Panel>
       
            <Panel header="9. Bagaimana cara melakukan isi ulang saldo A-Pay?" key="9">
            <p>Anterin Pay merupakan dompet digital yang bisa digunakan untuk: :</p>
            <ul>
                <li>Klik ‘isi saldo’ pada halaman utama aplikasi Anterin</li>
                <li>Tulis nominal yang di inginkan untuk top up, minimal Rp. 10,000</li>
                <li>Transfer ke nomor rekening yang tertera dengan nominal yang sesuai hingga 3 digit angka unik terakhir, agar saldo AnterinPay dapat diproses</li>
                <li>Refresh halaman utama, dan saldo akan masuk maksimal dalam 30 menit</li>  
            </ul>
          
            </Panel>
            </Collapse>
            

        <Divider/>
        <h2>Berlangganan</h2>  
        <Collapse onChange={callback} accordion> 
            <Panel header="10. Bagaimana cara Warga membeli dan aktivasi paket di aplikasi Anterin yang baru? " key="10">
            <p>Pada aplikasi baru, pembelian token dan aktivasi token sudah dibuat menjadi satu proses. Sehingga, istilah yang digunakan pada aplikasi baru adalah aktivasi token bukan pembelian token. Pembelian token hanya bisa menggunakan AnterinPay. </p>
            
            <ul>
                <p>Berikut langkah-langkah untuk aktivasi token / paket:</p>
                </ul>
                <ul>
                    <li>Buka menu AnterinPay</li>
                    <li>Pilih "Isi Ulang A-Token</li>
                    <li>Pilih paket yang di inginkan, pastikah saldo AnterinPay cukup.</li>
                    <li>Tekan tombol "Aktivasi"</li>  
                    <li>Sistem akan menampilkan konfirmasi aktivasi paket. Pilih ya jika ingin dilanjutkan. pilih tidak jika ingin membatalkan. </li> 
                    <li>Jika berhasil, sistem akan menampilkan pesan berhasil dan masa aktif paket akan tampil di halaman dashboard. </li>
                </ul>
            </Panel>
  
            <Panel header="11. Bagaimana cara melakukan pengecekan voucher perjalanan (voucher ride) sudah dicairkan?  " key="11">
            <p>Untuk driver (Warga) Anterin tidak ada fitur pengecekan voucher dan driver (Warga) Anterin tidak memiliki voucher untuk di cairkan. Yang bisa dicairkan oleh driver (Warga) Anterin adalah Anterin Pay dan A-Poin. Proses pencairan berlangsung dalam 1x24 jam.</p>
            </Panel>
        </Collapse>

        <Divider/>
      </div>
    }
  }

export default class FAQ extends Component{

    
    render(){
        return(
            <div>
                <div className="section-banner section-blue ">
                    <h2 style={{color:'white', margin:'1%',fontWeight:'bold'}}> Frequenly Asked Quetion</h2>
                </div>

                <CoolTabs className="tab" 
                    tabKey={'1'}
                    style={{height:4000, width:'100%', background:  'white', potition: 'sticky' }}
                    activeTabStyle={{ background:  'white', color:  '#12B1D3' }}
                    unActiveTabStyle={{ background:  '#12B1D3', color:  'white', opacity:0.7 }}
                    leftTabTitle={'Teman'}
                    rightTabTitle={'Warga'}
                    leftContent={<Content1/>}
                    rightContent={<Content2/>}
                    contentTransitionStyle={'transform 0.6s ease-in'}
                    borderTransitionStyle={'all 0.6s ease-in'}/>     
            </div>

        )
    }
}
